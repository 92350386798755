import React, {useState, useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import './Gallery.css';
import ImageGrid from "./ImageGrid";

import Cardboard from "./backgroundImages/Cardboard.png";
import ConcreteWall from "./backgroundImages/ConcreteWall.png";
import ExclusivePaper from "./backgroundImages/ExclusivePaper.png";
import Felt from "./backgroundImages/Felt.png";
import LinedPaper from "./backgroundImages/LinedPaper.png";
import NaturalPaper from "./backgroundImages/NaturalPaper.png";
import RicePaper from "./backgroundImages/RicePaper.png";
import Stardust from "./backgroundImages/Stardust.png";
import WhiteFeathers from "./backgroundImages/WhiteFeathers.png";


const Gallery = () => {
    const {destination = ""} = useParams();  // Access destination from the URL
    const [title, setTitle] = useState("");
    const [items, setItems] = useState([]);
    const [backgroundColor, setBackgroundColor] = useState("");
    const [titleColor, setTitleColor] = useState("");
    const [backgroundImage, setBackgroundImage] = useState("");

    const loadImages = (destination) => {
        switch (destination) {
            case "New Zealand":
                setTitle("New Zealand - 2019");
                setItems(require('./travelsImages/newZealandImages.json'));
                setBackgroundColor("aliceblue");
                setTitleColor("forestgreen");
                setBackgroundImage(ExclusivePaper);
                break;
            case "Myanmar":
                setTitle("Myanmar - 2018");
                setItems(require('./travelsImages/myanmarImages.json'));
                setBackgroundColor("honeydew");
                setTitleColor("coral");
                setBackgroundImage(ConcreteWall);
                break;
            case "China":
                setTitle("China - 2018");
                setItems(require('./travelsImages/chinaImages.json'));
                setBackgroundColor("whitesmoke");
                setTitleColor("tomato");
                setBackgroundImage(Stardust);
                break;
            case "Japan":
                setTitle("Japan - 2018");
                setItems(require('./travelsImages/japanImages.json'));
                setBackgroundColor("seashell");
                setTitleColor("lightsalmon");
                setBackgroundImage(WhiteFeathers);
                break;
            case "Ireland":
                setTitle("Ireland - 2020");
                setItems(require('./travelsImages/irelandImages.json'));
                setBackgroundColor("azure");
                setTitleColor("dodgerblue");
                setBackgroundImage(NaturalPaper);
                break;
            case "Singapore":
                setTitle("Singapore - 2018");
                setItems(require('./travelsImages/singaporeImages.json'));
                setBackgroundColor("lightgrey");
                setTitleColor("goldenrod");
                setBackgroundImage(LinedPaper);
                break;
            case "Iceland":
                setTitle("Iceland - 2021");
                setItems(require('./travelsImages/icelandImages.json'));
                setBackgroundColor("azure");
                setTitleColor("darkorange");
                setBackgroundImage(Felt);
                break;
            case "Taiwan":
                setTitle("Taiwan - 2018");
                setItems(require('./travelsImages/taiwanImages.json'));
                setBackgroundColor("whitesmoke");
                setTitleColor("gold");
                setBackgroundImage(Cardboard);
                break;
            case "Cambodia":
                setTitle("Cambodia - 2018");
                setItems(require('./travelsImages/cambodiaImages.json'));
                setBackgroundColor("mintcream");
                setTitleColor("dimgray");
                setBackgroundImage(RicePaper);
                break;
            default:
                setTitle("New Zealand - 2019");
                setItems(require('./travelsImages/newZealandImages.json'));
                setBackgroundColor("aliceblue");
                setTitleColor("forestgreen");
                setBackgroundImage(RicePaper);
                break;
        }
    };

    useEffect(() => {
        loadImages(destination);
    }, [destination]);

    return (
        <div id='GalleryWrapper' style={{
            backgroundColor: backgroundColor,
            backgroundImage: `url(${backgroundImage})`,
            backgroundAttachment: 'local'
        }}>
            <div id='GalleryHeader'>
                <Link id='GalleryReturnLink' to='/'>
                    <b>↢</b> Discover More!
                </Link>
                <div id='GalleryTitle' style={{color: titleColor}}>
                    {title}
                </div>
            </div>
            <div id='ImageGrid'>
                <ImageGrid items={items}/>
            </div>
        </div>
    );
};

export default Gallery;
